import {get, post} from "@/service/index";

// 获取合并配置
export const getInvoiceMergeConfig = (data) => {
    return get("/sal/config/getInvoiceMergeConfig", data).then((res) => res);
};

// 设置合并配置
export const setInvoiceMergeConfig = (data) => {
    return post("/sal/config/setInvoiceMergeConfig", data).then((res) => res);
};
