<template>
  <div>
    <!-- 发票合并 -->
    <el-dialog :visible.sync="visible" align="center" append-to-body title="系统提示" width="30%">
      <div>{{ content }}</div>
      <div slot="footer">
        <el-button type="primary" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 合并规则-->
    <el-dialog :visible.sync="dialogVisible" title="请选择发票/明细合并规则" width="35%">
      <div style="display: flex;margin-top: -50px;">
        <span style="width: 25%">
          <el-radio v-model="radio" label="1">开票配置</el-radio>
        </span>
        <div>
          <i class="el-icon-info"></i>
          <el-input disabled placeholder="按照开票配置>明细合并规则，进行发票/明细合并处理。"
                    style="padding-left:5px;width: 380px"></el-input>
        </div>
      </div>
      <div style="display: flex;padding-top: 15px">
        <span style="width: 25%">
          <el-radio v-model="radio" label="2">自定义配置</el-radio>
        </span>
        <div>
          <i class="el-icon-info"></i>
          <el-input disabled placeholder="按照自定义合并规则，进行发票/明细合并处理。"
                    style="padding-left:5px;width: 380px"></el-input>
        </div>
      </div>
      <div v-if="radio === '2'" style="display: block">
        <div style="padding-top:15px;padding-left: 25px;font-size: 15px;text-align: left;">发票单据：</div>
        <div style="display: flex;padding-top: 15px">
           <span style="width: 25%;padding-left: 1.5em;">
             <el-checkbox checked disabled>同销方信息、购方信息、发票类型均会合并为一张待开发票。</el-checkbox>
           </span>
        </div>
        <div style="display: flex;padding-top: 15px">
          <span style="width: 25%">
            <el-checkbox v-model="sameDataIdChecked">同业务单号</el-checkbox>
          </span>
          <div>
            <i class="el-icon-info"></i>
            <el-input disabled placeholder="按照开票配置>明细合并规则，进行发票/明细合并处理。"
                      style="padding-left:5px;width: 380px"></el-input>
          </div>
        </div>
        <div style="padding-bottom: 15px;">
          <div style="padding-top:15px;padding-left: 25px;font-size: 15px;text-align: left;">发票明细：</div>
          <div style="display: flex;align-items: center;padding-left: 25px;padding-top: 15px;">
            <el-checkbox v-model="itemCheckedRules.code" style="width: 18%">同商品编码</el-checkbox>
            <!--            <el-checkbox v-model="itemCheckedRules.middle" style="width: 18%">同商品中类</el-checkbox>-->
            <el-checkbox v-model="itemCheckedRules.dataId" style="width: 18%">同业务单号</el-checkbox>
            <el-checkbox v-model="itemCheckedRules.name" style="width: 18%">同商品名称</el-checkbox>
            <el-checkbox v-model="itemCheckedRules.unit" style="width: 18%">同商品单位</el-checkbox>
          </div>
          <div style="display: flex;align-items: center;padding-left: 25px;padding-top: 15px;">
            <el-checkbox v-model="itemCheckedRules.price" disabled style="width: 18%">同商品单价</el-checkbox>
            <el-checkbox v-model="itemCheckedRules.taxRate" disabled style="width: 18%">同商品税率</el-checkbox>
            <el-checkbox v-model="itemCheckedRules.taxClassCode" style="width: 18%">同税收编码</el-checkbox>
            <el-checkbox v-model="itemCheckedRules.specification" style="width: 18%">同规格型号</el-checkbox>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="padding-top: 15px;">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmMerge">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mergeInvoice} from '@/service/sal/invoice'
import {getInvoiceMergeConfig, setInvoiceMergeConfig} from "@/service/sal/sal-config";

export default {
  name: 'InvoiceMerge',
  props: {
    invoices: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      visible: false,
      content: '',
      dialogVisible: false,
      radio: '1',
      sameDataIdChecked: false,
      itemCheckedRules: {
        code: false,
        middle: false,
        name: true,
        unit: false,
        taxRate: true,
        taxClassCode: false,
        specification: false,
        price: true,
        dataId: false
      },
      customConfig: false
    }
  },
  created() {
    this.renderMergeConfig()
  },
  methods: {
    async renderMergeConfig() {
      const {success, data} = await getInvoiceMergeConfig()
      if (success && data && data.customConfig) {
        this.radio = data.radio
        this.customConfig = data.customConfig
        this.itemCheckedRules = data.itemCheckedRules
        this.sameDataIdChecked = data.sameDataIdChecked
      }
    },
    open () {
      this.handleMerge()
    },
    handleClose () {
      this.visible = false
    },
    /* 合并发票 */
    handleMerge () {
      const message = this.validateParam()
      if (message != '') {
        this.content = message
        this.visible = true
        return
      }
      this.dialogVisible = true
    },
    confirmMerge() {
      const message = this.validateInvoices()
      if (message) {
        this.content = message
        this.visible = true
        return
      }
      if (this.radio === '2' && !this.itemCheckedRules.code && !this.itemCheckedRules.name) {
        this.$confirm('商品编码、同商品名称,必须选中一项', '提示', {type: 'warning'})
        return
      }
      // 合并发票
      let ids = this.invoices.map(item => item.id)
      let json = {'ids': ids}
      //自定义配置
      let arr = []
      if (this.radio === '2') {
        json.customConfig = true
        this.customConfig = true
        if (this.itemCheckedRules.code) {
          arr.push("merchandiseCode")
        }
        if (this.itemCheckedRules.middle) {
          arr.push("middle")
        }
        if (this.itemCheckedRules.taxRate) {
          arr.push("taxRate")
        }
        if (this.itemCheckedRules.name) {
          arr.push("merchandiseName")
        }
        if (this.itemCheckedRules.unit) {
          arr.push("unit")
        }
        if (this.itemCheckedRules.taxClassCode) {
          arr.push("taxClassCode")
        }
        if (this.itemCheckedRules.price) {
          arr.push("price")
        }
        if (this.itemCheckedRules.specification) {
          arr.push("specification")
        }
        json.itemMergeRules = arr
      }
      this.$confirm('是否确认合并发票信息？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        mergeInvoice(json).then(async res => {
          if (res.success) {
            this.$message.success('合并发票成功！')
            this.$emit('success')
            this.dialogVisible = false

            //保存配置
            let conf = {
              radio: this.radio,
              sameDataIdChecked: this.sameDataIdChecked,
              customConfig: this.customConfig,
              itemCheckedRules: this.itemCheckedRules
            }
            await setInvoiceMergeConfig(conf)
          }
        })
      }).catch(() => {
        this.$message('已取消合并发票', 'info')
      })
    },
    validateParam () {
      let invoices = this.invoices
      if (invoices.length <= 1) {
        return '请选择至少两条发票数据！'
      }
      return ''
    },
    validateInvoices() {
      let invoices = this.invoices
      // 购方信息
      let buyers = invoices.map(item => item.buyerName + item.buyerTaxNo)
      if ([...new Set(buyers)].length > 1) {
        return '所选发票的购方信息不一致，请重新选择！'
      }

      // 销方信息
      let sellers = invoices.map(item => item.sellerName + item.sellerTaxNo)
      if ([...new Set(sellers)].length > 1) {
        return '所选发票的销方信息不一致，请重新选择！'
      }

      // 发票类型
      let types = invoices.map(item => item.billingType)
      if ([...new Set(types)].length > 1) {
        return '所选发票的发票类型不一致，请重新选择！'
      }
      if (this.radio === '2' && this.sameDataIdChecked) {
        // 业务单号
        let dataIds = invoices.map(item => item.dataId)
        if ([...new Set(dataIds)].length > 1) {
          return '所选发票的业务单号不一致，请重新选择！'
        }
      }
    }
  }
}
</script>
