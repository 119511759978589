<template>
  <!-- 删除弹窗 -->
  <el-dialog append-to-body title="系统提示" :visible.sync="deleteVisible" width="550px">
    <div class="delete-name">
      <label>{{ name }}</label>
    </div>
    <div class="delete-cont" v-if="len==1">
      <el-row v-if="invoice.roomId">
        <label>房间号：</label>
        <span>{{ invoice.roomId }}</span>
      </el-row>
      <el-row>
        <label>购方名称：</label>
        <span>{{ invoice.buyerName }}</span>
      </el-row>
      <el-row>
        <label>销方名称：</label>
        <span>{{ invoice.sellerName }}</span>
      </el-row>
      <el-row>
        <label>价税合计：</label>
        <span>{{ invoice.sumAmount }}元</span>
      </el-row>
    </div>
    <div class="delete-tips" v-if="!resultVisible">
      <span>确认删除之前，注意并了解以下内容：</span><br/>
      <ul class="ts">
        <li>若选中的数据中存在已拆分发票，删除该类发票时会一同删除关联发票；</li>
        <li v-if="froms.has('SB')||froms.has('EB')||froms.has('DB')">若选中的数据中存在关联订单对应发票已经开具，系统将自动忽略该票；</li>
        <li v-if="froms.has('SB')||froms.has('EB')||froms.has('DB')">删除发票之后对应关联订单的“生成发票”状态会被重置为“未生成”；</li>
        <li v-if="sources.has('FZBK')">删除后不可恢复，需客人在“菲住布渴”小程序重新提交或者手工开票！</li>
        <li v-if="!sources.has('FZBK')">发票删除之后，系统将无法恢复或找回数据，请您认真阅读并谨慎操作！</li>
      </ul>
    </div>
    <div class="delete-tips" v-if="resultVisible">
      <ul class="jg">
        <li v-for="(item,idx) in resultMessages" :key="idx">{{item}}</li>
      </ul>
    </div>
    <div slot="footer">
      <el-button type="default" @click="handleCancel">{{ resultVisible ? '关闭' : '取消'}}</el-button>
      <el-button type="primary" v-if="!resultVisible" :disabled="deleteDisabled" :loading="deleteDisabled"
                 @click="handleDelete">删除
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { deleteInvoice, batchDeleteInvoice } from '@/service/sal/invoice'

export default {
  name: 'InvoiceDelete',
  props: {
    invoices: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      checkLoading: false,
      deleteVisible: false,
      deleteDisabled: false,
      resultVisible: false,
      resultMessages: []
    }
  },
  computed: {
    len () {
      return this.invoices.length
    },
    name () {
      return this.resultVisible ? `总计有${this.resultMessages.length}张发票删除失败！` : `是否确认删除${this.len == 1 ? '此' : this.len}张发票数据？`
    },
    froms () {
      return new Set(this.invoices.map(item => item.billingFrom))
    },
    invoice () {
      return !this.len ? {} : this.invoices[0]
    },
    sources () {
      return new Set(this.invoices.map(item => item.source))
    }
  },
  methods: {
    open () {
      this.resultVisible = false
      this.resultMessages = []
      if (this.invoices.length == 0) {
        this.toast('请选择需要删除的发票数据！', 'warning')
        return
      }

      this.deleteVisible = true
    },
    handleCancel () {
      this.deleteVisible = false
    },
    handleDelete () {
      if (this.len == 1) {
        deleteInvoice(this.invoice.id).then((res) => {
          if (res.success) {
            this.deleteVisible = false
            this.$message.success('发票删除成功')
            this.$emit('success')
          }
        })
      } else if (this.len > 1) {
        batchDeleteInvoice({ ids: this.invoices.map(item => item.id) }).then((res) => {
          if (res.success) {
            if (res.data instanceof Array) {
              this.resultMessages = res.data
              this.resultVisible = true
            } else {
              this.deleteVisible = false
              this.$message.success('发票删除成功')
            }
            this.$emit('success')
          }
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .el-dialog__body {
    color: #606266;
    font-size: 14px;
    text-align: left;
    padding: 10px 20px 30px 20px;
  }

  .delete-name {
    font-size: 16px;
    padding-bottom: 30px;
  }

  .delete-cont {
    padding-bottom: 30px;
    label {
      width: 100px;
      display: inline-block;
    }
    label, span {
      line-height: 1.6em;
    }
  }

  .delete-tips {
    font-family: 黑体;

    .ts {
      list-style: auto;
      li {
        color: red;
        line-height: 1.4em;
      }
    }

    .jg {
      list-style: circle;
      li {
        color: red;
        line-height: 1.4em;
      }
    }
  }
</style>
