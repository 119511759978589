<template>
  <el-drawer append-to-body :with-header="false" :visible.sync="visible" :show="show" @close="$emit('update:show', false)" size="900px">
    <div class="open-invoice-details">
      <el-tabs v-model="activeName">
        <el-tab-pane label="发票详情" name="first">
          <div class="invoice-top">
            <label-span label="发票类型：" :span="handleValueToLabel('BillingType', invoice.billingType)" />
            <label-span label="发票状态：" :span="handleValueToLabel('InvoiceStatus', invoice.invoiceStatus)" />
          </div>
          <div class="invoice-middle">
            <div class="middle-average">
              <div class="first-row">
                <span>购买方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">名称:</label>
                <div>{{ invoice.buyerName }}</div>
              </div>
              <div class="other-row">
                <label-span label="税号：" :span="invoice.buyerTaxNo"></label-span>
              </div>
              <div class="other-row">
                <label>地址电话:</label>
                <el-tooltip effect="dark" :content="invoice.buyerAddressTel" placement="top-start">
                  <span style="max-width: 18em">{{ invoice.buyerAddressTel }}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">开户行及账号：</label>
                <el-tooltip effect="dark" :content="invoice.buyerBankAccount" placement="top-start">
                  <span style="max-width: 17em">{{ invoice.buyerBankAccount }}</span>
                </el-tooltip>
              </div>
            </div>
            <div class="middle-average">
              <div class="first-row">
                <span>销售方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">名称:</label>
                <div>{{ invoice.sellerName }}</div>
              </div>
              <div class="other-row">
                <label-span label="税号：" :span="invoice.sellerTaxNo"></label-span>
              </div>
              <div class="other-row">
                <label>地址电话:</label>
                <el-tooltip effect="dark" :content="invoice.sellerAddressTel" placement="top-start">
                  <span style="max-width: 18em">{{ invoice.sellerAddressTel }}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">开户行及账号：</label>
                <el-tooltip effect="dark" :content="invoice.sellerBankAccount" placement="top-start">
                  <span style="max-width: 17em">{{ invoice.sellerBankAccount }}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div>
            <el-button style="padding-left: 0" @click="openDetail" type="text">开票明细</el-button>
            <!--            <el-row type="flex" justify="space-between">-->
            <!--              <el-col>-->
            <!--                -->
            <!--              </el-col>-->
            <!--&lt;!&ndash;              <el-col align="right">&ndash;&gt;-->
            <!--&lt;!&ndash;                <el-checkbox v-model="isShowName">显示税收分类名称</el-checkbox>&ndash;&gt;-->
            <!--&lt;!&ndash;                <el-checkbox v-model="isShowCode">显示税收分类编码</el-checkbox>&ndash;&gt;-->
            <!--&lt;!&ndash;              </el-col>&ndash;&gt;-->
            <!--            </el-row>-->

            <!--            <div class="bottom-table">-->
            <!--              <el-table :data="tableData" border stripe :span-method="arraySpanMethod" :header-cell-style="handleHeaderCellStyle" max-height="280">-->
            <!--                <el-table-column prop="merchandiseName" fixed width="150" label="货物或应税服务名称" :formatter="fmtTableColumn" />-->
            <!--                <el-table-column prop="specification" label="规格型号" />-->
            <!--                <el-table-column prop="unit" label="单位" />-->
            <!--                <el-table-column prop="quantity" label="数量" />-->
            <!--                <el-table-column prop="unTaxPrice" label="单价" />-->
            <!--                <el-table-column prop="amount" label="金额" />-->
            <!--                <el-table-column prop="taxRate" label="税率" :formatter="fmtTableColumn" />-->
            <!--                <el-table-column prop="taxAmount" label="税额" :formatter="fmtTableColumn" />-->
            <!--                <el-table-column v-if="isShowName" prop="taxClassName" label="分类名称" fixed="right" width="110" />-->
            <!--                <el-table-column v-if="isShowCode" prop="taxClassCode" label="分类编码" fixed="right" width="110" />-->
            <!--              </el-table>-->
            <!--            </div>-->
          </div>
          <div class="invoice-remark">
            <div class="remark-title">备注</div>
            <div class="remark-content">
              <p v-html="invoice.remark" style="white-space: pre-line"></p>
            </div>
          </div>

          <div class="open-invoice-message">
            <div class="one-col">
              <!-- <div class="second-row">
                <label style="min-width: 42px">设备分机编号 :</label>
                <div>{{ invoice.equipmentExtensionNo }}</div>
              </div> -->
              <div class="second-row">
                <label style="min-width: 42px">发票代码 :</label>
                <div>{{ invoice.invoiceCode }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">发票号码 :</label>
                <div>{{ invoice.invoiceNo }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">开票时间 :</label>
                <div>{{ invoice.invoiceTime }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">开票来源 :</label>
                <div>{{ handleValueToLabel('BillingFrom', invoice.billingFrom) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">自动开票 :</label>
                <div>{{ invoice.autoBilling == true ? '是' : '否' }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">开具标识 :</label>
                <div>{{ handleValueToLabel('BillingFlag', invoice.billingFlag) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">数据标识 :</label>
                <div>{{ handleValueToLabel('DataFlag', invoice.dataFlag) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">订单编号:</label>
                <div>{{ showMessage(invoice.dataId) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">业务单号:</label>
                <div>{{ showMessage(invoice.businessCode) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">发票打印状态 :</label>
                <div>{{ handleValueToLabel('PrintingStatus', invoice.invoicePrintStatus) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">清单打印状态 :</label>
                <div>{{ handleValueToLabel('PrintingStatus', invoice.detailsPrintStatus) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">开票通道 :</label>
                <div>{{ getDictLabel(equBillingMode, invoice.billingChannel) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">发票状态 :</label>
                <div>{{ handleValueToLabel('InvoiceStatus', invoice.invoiceStatus) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">是否回写 :</label>
                <div>{{ invoice.isWriteBack == true ? '是' : '否' }}</div>
                <div style="color: #409eff; cursor: pointer" @click="handleInvoiceReturn">数据回调</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">回写状态 :</label>
                <div>{{ handleValueToLabel('WriteBackStatus', invoice.writeBackStatus) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">错误消息 :</label>
                <div>{{ showMessage(invoice.failedMessage) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">红字通知单号 :</label>
                <div>{{ showMessage(invoice.redNoticeNo) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">原发票代码 :</label>
                <div>{{ showMessage(invoice.originInvoiceCode) }}</div>
              </div>
            </div>
            <div class="one-col">
              <div class="second-row">
                <label style="min-width: 42px">原发票号码 :</label>
                <div>{{ showMessage(invoice.originInvoiceNo) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">收款人 :</label>
                <div>{{ invoice.payee }}</div>
              </div>
              <div class="second-row">
                <!-- <label style="min-width: 42px">复核人 :</label>
                <div>{{ invoice.auditor }}</div> -->
              </div>
              <div class="second-row">
                <label style="min-width: 42px">开票人 :</label>
                <div>{{ invoice.payer }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">校验码 :</label>
                <div>{{ invoice.checkCode }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">发票金额 :</label>
                <div>{{ invoice.amount }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">发票税额 :</label>
                <div>{{ invoice.taxAmount }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">价税合计 :</label>
                <div>{{ invoice.sumAmount }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">税率 :</label>
                <div>{{ handleTaxRate(invoice.taxRate) }}</div>
              </div>
              <!-- <div class="second-row">
                <label style="min-width: 42px">机器编号 :</label>
                <div>{{ showMessage(invoice.machineNo) }}</div>
              </div> -->
              <div class="second-row">
                <label style="min-width: 42px">影像类型 :</label>
                <div>{{ invoice.imageType }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">蓝票请求流水号 :</label>
                <div>{{ invoice.billSerialNo }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">红票请求流水号 :</label>
                <div>{{ showMessage(invoice.redSerialNo) }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">创建时间 :</label>
                <div>{{ invoice.created }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">更新时间 :</label>
                <div>{{ invoice.updated }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">数电发票号码 :</label>
                <div>{{ invoice.eInvoiceNo }}</div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">影像原始地址 :</label>
                <div>
                  <a v-if="invoice.imageUrl" target="_blank" style="margin-right: 10px" :href="invoice.imageUrl">PDF版式</a>
                  <a v-if="invoice.imageOfdUrl" target="_blank" style="margin-right: 10px" :href="invoice.imageOfdUrl">OFD版式</a>
                  <a v-if="invoice.imageXmlUrl" target="_blank" style="margin-right: 10px" :href="invoice.imageXmlUrl">XML版式</a>
                </div>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">发票主键 :</label>
                <div>{{ invoice.id }}</div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="寄回信息" name="second" v-if="industry">
          <el-row class="row-first first-row">
            <el-col :span="12">
              <label-span label="取票方式：" :span="handleValueToLabel('BillingSendMode', sendBackInfo.sendMode)"></label-span>
            </el-col>
          </el-row>
          <el-row class="row-first" v-if="sendBackInfo.sendMode == 'Kd'">
            <el-col :span="12">
              <label-span label="邮寄地址：" :span="sendBackInfo.expressAddress"></label-span>
            </el-col>
          </el-row>
          <el-row class="row-first" v-if="sendBackInfo.sendMode == 'Kd'">
            <el-col :span="12">
              <label-span label="联系人：" :span="sendBackInfo.expressContact"></label-span>
            </el-col>
          </el-row>
          <el-row class="row-first" v-if="sendBackInfo.sendMode == 'Kd'">
            <el-col :span="12">
              <label-span label="联系电话：" :span="sendBackInfo.expressPhone"></label-span>
            </el-col>
          </el-row>
          <el-row class="row-first" v-if="sendBackInfo.sendMode == 'Dx'">
            <el-col :span="12">
              <label-span label="联系电话：" :span="sendBackInfo.receivePhone"></label-span>
            </el-col>
          </el-row>
          <el-row class="row-first" v-if="sendBackInfo.sendMode == 'Yj'">
            <el-col :span="12">
              <label-span label="邮件地址：" :span="sendBackInfo.receiveEmail"></label-span>
            </el-col>
          </el-row>
          <el-row class="row-first" v-if="sendBackInfo.receiveRemark != null && sendBackInfo.receiveRemark != ''">
            <el-col :span="12">
              <label-span label="取票备注：" :span="sendBackInfo.receiveRemark"></label-span>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import { invoiceReturn } from '@/service/sal/invoice.js';
import { tableStyle, changeMethods } from '@/util/mixins';

export default {
  mixins: [tableStyle, changeMethods],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    invoice: Object
  },
  data() {
    return {
      isShowName: false,
      isShowCode: false,
      visible: this.show,
      activeName: 'first',
      equBillingMode: []
    };
  },
  created() {
    this.getDicts('equ_billing_mode').then(({ data }) => (this.equBillingMode = data));
  },
  watch: {
    show() {
      this.visible = this.show;
    }
  },
  computed: {
    tableData() {
      return this.invoice.items;
    },
    sendBackInfo() {
      return this.invoice.sendBackInfo ? this.invoice.sendBackInfo : {};
    },
    industry() {
      return this.invoice.industry == 'hotel';
    }
  },
  methods: {
    // 数据回调
    handleInvoiceReturn() {
      if (this.invoice.isWriteBack && this.handleValueToLabel('WriteBackStatus', this.invoice.writeBackStatus) == '回写成功') {
        this.$confirm('发票信息已回写，是否重新回调?')
          .then(() => {
            this._invoiceReturn();
          })
          .catch(() => {
            return 0;
          });
        return;
      }
      this._invoiceReturn();
    },
    _invoiceReturn() {
      const loading = this.$loading({
        lock: true,
        text: '数据回调中，请稍后。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      invoiceReturn({ forced: true, id: this.invoice.id }).then((res) => {
        const { message, success } = res;
        loading.close();
        if (success) {
          this.toast(message, 'success');
          this.$emit('refreshDetail');
        }
      });
    },
    // arraySpanMethod({ rowIndex, columnIndex }) {
    //   const openDetailsLength = this.invoice.items.length;
    //   if (rowIndex === openDetailsLength - 1) {
    //     if (columnIndex === 0) {
    //       return [1, 1];
    //     } else if (columnIndex === 1) {
    //       return [1, 4];
    //     } else {
    //       return [1, 5];
    //     }
    //   }
    // },
    isPreferential(val) {
      return val == '免税' || val == '不征税';
    },
    // 格式化税率、税额
    // fmtTableColumn(row, column) {
    //   switch (column.property) {
    //     case 'taxRate':
    //       return this.isPreferential(row.taxPreferentialContent) ? row.taxPreferentialContent : row.taxRate;
    //     case 'taxAmount':
    //       return this.isPreferential(row.taxPreferentialContent) ? '***' : row.taxAmount;
    //     case 'merchandiseName':
    //       return row.taxClassName != null ? '*' + row.taxClassName + '*' + row.merchandiseName : row.merchandiseName;
    //   }
    // },
    showMessage(message) {
      if (message == '' || message == undefined || message == null) {
        return '--';
      }
      return message;
    },
    handleTaxRate(val) {
      if (!isNaN(val)) {
        if (val == '') {
          return '';
        }
        return val * 100 + '%';
      } else {
        return val;
      }
    },
    openDetail() {
      this.$emit('openDetail', this.invoice.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.open-invoice-details {
  margin: 0 24px;

  ::v-deep .el-tabs__nav {
    margin: 0;
  }

  .invoice-top {
    display: flex;
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #cccccc;

    .label-span {
      margin-right: 86px;
    }
  }

  .invoice-middle {
    display: flex;
    margin-top: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid #cccccc;

    .middle-average {
      width: 400px;

      .first-row {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }
      }

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }

      .other-row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-right: 20px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .invoice-bottom {
    margin-top: 32px;
    height: calc(100% - 225px);
    border-bottom: 1px solid #cccccc;
    padding-bottom: 32px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .bottom-table {
      margin-top: 16px;
    }
  }

  .invoice-remark {
    margin-top: 24px;

    .remark-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .remark-content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 24px;
      border-bottom: 1px solid #cccccc;
    }
  }

  .open-invoice-message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-bottom: 24px;
    display: flex;

    .one-col {
      width: 400px;

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }
    }
  }
}

.el-row {
  margin-bottom: 16px;

  &:first-child {
    margin-top: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .label-span {
    span {
      margin-left: 8px;
    }
  }
}

::v-deep .el-drawer__body {
  overflow: auto;
}
</style>
