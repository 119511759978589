<template>
  <el-dialog append-to-body
             :visible.sync="downloadVisible"
             title="模版下载"
             width="450px">
    <div class="tmpl-cont">
      <a :href="staticFile.invoiceImportTsv.url" :download="staticFile.invoiceImportTsv.name">{{staticFile.invoiceImportTsv.name}}</a>
      <br/>
      <a :href="staticFile.invoiceImportXls.url" :download="staticFile.invoiceImportXls.name">{{staticFile.invoiceImportXls.name}}</a>
    </div>
    <div slot="footer">
      <el-button type="default" @click="downloadVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import StaticFile from '@/assets/js/static-file'

export default {
  name: 'InvoiceTmpl',
  data () {
    return {
      staticFile: StaticFile,
      downloadVisible: false,
    }
  },
  methods: {
    open () {
      this.downloadVisible = true
    },
    handleCancel () {
      this.downloadVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .tmpl-cont {
    padding: 0px 20px 20px 20px;

    a, a:hover {
      color: #3D94FF;
      font-size: 16px;
      font-weight: 400;
      line-height: 2em;
      text-decoration: underline;
    }
  }
</style>
