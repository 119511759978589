<template>
  <el-dialog append-to-body :visible.sync="show" :show-close="false" v-if="show" :close-on-click-modal="false" :close-on-press-escape="false" title="修改抬头信息" @close="$emit('update:show', false)" width="500px">
    <div class="invoice-title-form">
      <el-form :model="invoiceTitle" ref="invoiceTitleForm" :rules="invoiceTitleRules">
        <div class="edit-select">
          <div class="edit-select-item">
            <el-form-item prop="buyerName" label="客户名称">
              <el-input v-model="invoiceTitle.buyerName"></el-input>
            </el-form-item>
            <el-form-item prop="buyerTaxNo" label="客户税号">
              <el-input v-model="invoiceTitle.buyerTaxNo"></el-input>
            </el-form-item>
          </div>
          <div class="edit-select-item">
            <el-form-item class="edit-input" prop="buyerAddressTel" label="地址电话">
              <el-input v-model="invoiceTitle.buyerAddressTel"></el-input>
            </el-form-item>
          </div>
          <div class="edit-select-item">
            <el-form-item class="edit-input" prop="buyerBankAccount" label="银行账号">
              <el-input v-model="invoiceTitle.buyerBankAccount"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="default" @click="$emit('update:show', false)">取消</el-button>
      <el-button type="primary" @click="handleUpdateTitle">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateInvoiceBuyer } from '@/service/sal/invoice';

export default {
  name: 'InvoiceTitleUpdate',
  props: ['show', 'invoiceTitle'],
  watch: {
    invoiceTitle: {
      handler() {
        this.invoiceTitleRules.buyerTaxNo = [];
        this.invoiceTitleRules.buyerAddressTel = [];
        this.invoiceTitleRules.buyerBankAccount = [];

        if (['Zzzp', 'QDZP', 'DZZP'].indexOf(this.invoiceTitle?.billingType) != -1) {
          this.invoiceTitleRules.buyerTaxNo = [{ required: true, message: '请输入购方税号', trigger: 'blur' }];
        }
        if (['Zzzp', 'DZZP'].indexOf(this.invoiceTitle?.billingType) != -1) {
          this.invoiceTitleRules.buyerAddressTel = [{ required: true, message: '请输入地址电话', trigger: 'blur' }];
          this.invoiceTitleRules.buyerBankAccount = [{ required: true, message: '请输入银行账号', trigger: 'blur' }];
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      invoiceTitleRules: {
        buyerName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        buyerTaxNo: [],
        buyerAddressTel: [],
        buyerBankAccount: []
      }
    };
  },
  methods: {
    handleUpdateTitle() {
      this.$refs.invoiceTitleForm.validate((valid) => {
        if (valid) {
          updateInvoiceBuyer(this.invoiceTitle).then((res) => {
            if (res.success) {
              this.toast('抬头修改成功', 'success', () => {
                this.$emit('update:show', false);
              });
              this.$emit('success');
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.invoice-title-form {
  padding: 0 24px;
}

.edit-select {
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .edit-input .el-input {
      width: 450px;
    }
  }

  .edit-select-foot {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

::v-deep .el-form-item__label {
  padding: 0px 0px 0px 0px;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 64px;
}

::v-deep .el-dialog__body {
  margin-top: 0px;
}
</style>
