<template>
  <div>
    <!-- 明细信息 -->
    <el-drawer append-to-body size="50%" :with-header="false" :visible.sync="listVisible" direction="btt">
      <div class="drawer-header">
        <span>发票明细</span>
        <div>
          <el-button type="text" style="padding: 0" @click="handleSync">同步商品</el-button>
          <el-button type="text" @click="handleSplitInvoiceItem">拆分明细</el-button>
          <el-button type="text" @click="handleInvoiceMergeDetails">合并明细</el-button>
          <el-button type="text" @click="handleInvoiceSplitBill">拆分发票</el-button>
        </div>
      </div>
      <el-form style="margin: 24px" :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="商品编码">
          <el-input v-model="formInline.merchandiseCode" placeholder="请输入商品编码"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="formInline.merchandiseName" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="税收分类编码">
          <el-input v-model="formInline.taxClassCode" placeholder="请输入税收分类编码"></el-input>
        </el-form-item>
        <el-form-item label="税收分类名称">
          <el-input v-model="formInline.taxClassName" placeholder="请输入税收分类名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onReset">重置</el-button>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="content-main">
        <div class="content-table">
          <el-table border stripe v-loading="dataLoading" :data="tableData" show-summary :summary-method="getSummaries" ref="multipleTable" @row-click="handleClickInvoiceItem" @selection-change="handleSelectionChange" :header-cell-style="handleHeaderCellStyle" :max-height="tableHeight" style="width: 100%">
            <el-table-column type="selection" width="50"/>
            <el-table-column type="index" label="序号" width="50" />
            <el-table-column prop="merchandiseCode" label="商品编码" show-overflow-tooltip />
            <el-table-column prop="merchandiseName" label="商品名称" show-overflow-tooltip />
            <el-table-column width="190px" prop="taxClassCode" label="税收分类编码" show-overflow-tooltip />
            <el-table-column width="130px" prop="taxClassName" label="税收分类名称" show-overflow-tooltip />
            <el-table-column prop="specification" label="规格型号" show-overflow-tooltip />
            <el-table-column prop="unit" label="商品单位" width="80" />
            <el-table-column prop="itemProperty" label="商品行性质" width="120" :formatter="handleTableValueFormat" />
            <el-table-column prop="quantity" label="数量" width="100" :formatter="handleTableValueFormat" />
            <el-table-column prop="unTaxPrice" label="单价" width="120" />
            <el-table-column prop="amount" label="金额" width="120" :formatter="handleTableValueFormat" />
            <el-table-column prop="taxRate" label="税率" width="70" :formatter="handleTableValueFormat" />
            <el-table-column prop="taxAmount" label="税额" width="120" :formatter="handleTableValueFormat" />
            <el-table-column prop="sumAmount" label="价税合计" width="120" :formatter="handleTableValueFormat" />
            <el-table-column label="业务单号" prop="dataId" show-overflow-tooltip />
            <el-table-column prop="operate" label="操作" fixed="right" width="100">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: flex-start">
                  <el-button type="text" style="padding: 0" @click="handleEdit(scope.row)">编辑</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <div class="content-page">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="getSelectItems.page" :page-sizes="pageSizes" :page-size="getSelectItems.size" layout="total, sizes, prev, pager, next" :total="dataTotal"></el-pagination>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 提示弹窗 -->
    <el-dialog append-to-body title="提示" :visible.sync="titleVisible" width="30%">
      <div class="dialog-content">{{ titleContent }}</div>
      <div class="dialog-footer" slot="footer">
        <el-button type="primary" @click="titleVisible = false">确定</el-button>
      </div>
    </el-dialog>

    <!-- 拆分明细弹窗 -->
    <el-dialog append-to-body title="拆分明细" :visible.sync="dialogVisible" width="80%" @close="handleSpiltDetailsCancel">
      <div class="dialog-header">
        <div class="header-left">
          <el-radio v-model="radio" label="1">按商品数量拆分</el-radio>
          <el-radio v-model="radio" label="2">按商品金额拆分</el-radio>
          <el-radio v-model="radio" label="3">按价税合计拆分</el-radio>
        </div>
        <div class="header-right">
          <div v-if="radio == 1">
            <label>总数量：</label>
            <span>{{ invoiceDetails.quantity }}</span>
          </div>
          <div v-if="radio == 2">
            <label>总金额：</label>
            <span>{{ invoiceDetails.amount }}</span>
          </div>
          <div v-if="radio == 3">
            <label>总价税合计：</label>
            <span>{{ invoiceDetails.sumAmount }}</span>
          </div>
        </div>
      </div>
      <div class="dialog-body">
        <el-table ref="fixed" :data="invoiceSplitDetails" border style="width: 100%" :header-cell-style="handleHeaderCellStyle">
          <el-table-column width="55" fixed="left">
            <template slot-scope="scope">
              <el-button v-if="scope.$index == 0" type="primary" @click="handleInvoiceDetailsSplitAdd"> + </el-button>
              <el-button v-else type="danger" @click="handleInvoiceSplitDelete(scope.row)">-</el-button>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号" width="46" />
          <el-table-column label="商品编码" prop="merchandiseCode" />
          <el-table-column prop="merchandiseName" label="商品名称" />
          <el-table-column prop="taxClassCode" label="税收分类编码" max-width="180" show-overflow-tooltip />
          <el-table-column prop="specification" label="规格型号" width="100" />
          <el-table-column prop="unit" label="商品单位" width="70" />
          <el-table-column prop="quantity" label="数量" width="130">
            <template slot-scope="scope">
              <el-input v-if="radio == '1' && scope.$index != 0" v-model="scope.row.quantity" onkeyup="value=value.replace(/[^0-9.]/g,'')" @input="handleCalculateNumber(scope.row)" @blur="handleFirstRowCalculate()" />
              <div v-else>
                {{ scope.row.quantity == 0 ? '' : scope.row.quantity }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="unTaxPrice" label="单价" width="120" :formatter="handleTableValueFormat" />
          <el-table-column prop="amount" label="金额" width="120">
            <template slot-scope="scope">
              <el-input v-if="radio == '2' && scope.$index != 0" v-model="scope.row.amount" onkeyup="value=value.replace(/[^0-9.]/g,'')" @input="handleCalculateAmount(scope.row)" @blur="handleFirstRowCalculate()" />
              <div v-else>{{ '￥' + scope.row.amount }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="taxRate" label="税率" width="80" :formatter="handleTableValueFormat" />
          <el-table-column prop="taxAmount" label="税额" width="120" :formatter="handleTableValueFormat" />
          <el-table-column prop="sumAmount" label="价税合计" width="120">
            <template slot-scope="scope">
              <el-input v-if="radio == '3' && scope.$index != 0" v-model="scope.row.sumAmount" onkeyup="value=value.replace(/[^0-9.]/g,'')" @input="handleCalculateSumAmount(scope.row)" @blur="handleFirstRowCalculate()" />
              <div v-else>{{ '￥' + scope.row.sumAmount }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleSpiltDetailsCancel">取消</el-button>
        <el-button type="primary" @click="handleSpiltDetailsConfirm">确定</el-button>
      </div>
    </el-dialog>

    <!-- 发票明细编辑弹框 -->
    <!-- <el-dialog class="ModificationOfInvoiceDetails" title="编辑" :visible.sync="dialogFormVisible" width="400px" label-position="right">
      <el-form :model="rowData" label-width="100px" style="width:300px , margin:0 auto">
        <el-form-item label="税收分类编码" style="margin:10px 0" >
        <el-input v-model="rowData.taxClassCode" autocomplete="off"></el-input>
      </el-form-item>
        <el-form-item label="商品名称" style="margin:10px 0" >
        <el-input v-model="rowData.taxClassName" autocomplete="off"></el-input>
      </el-form-item>
      </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormConfirm">确 定</el-button>
        </div>
    </el-dialog> -->
    <el-dialog  title="编辑明细信息" :visible.sync="itemEditVisible" >
      <invoice-item-edit ref="itemEditForm" @handleItemEditDialog="handleItemEditDialog" :invoice-detail="rowData" ></invoice-item-edit>
    </el-dialog>

    <!-- 合并规则-->
    <el-dialog :visible.sync="mergeVisible" title="请选择发票/明细合并规则" width="38%">
      <div style="margin: 0 24px">
        <div style="display: flex">
          <span style="width: 25%">
            <el-radio v-model="mergeRadio" label="1">开票配置</el-radio>
          </span>
          <div>
            <i class="el-icon-info"></i>
            <el-input disabled placeholder="按照开票配置>明细合并规则，进行发票/明细合并处理。" style="padding-left: 5px; width: 380px"></el-input>
          </div>
        </div>
        <div style="display: flex; padding-top: 15px">
          <span style="width: 25%">
            <el-radio v-model="mergeRadio" label="2">自定义配置</el-radio>
          </span>
          <div>
            <i class="el-icon-info"></i>
            <el-input disabled placeholder="按照自定义合并规则，进行发票/明细合并处理。" style="padding-left: 5px; width: 380px"></el-input>
          </div>
        </div>
        <div v-if="mergeRadio === '2'" style="display: block">
          <!--          <div style="padding-top:15px;font-size: 15px;text-align: left;">发票单据：</div>-->
          <!--          <div style="display: flex;padding-top: 15px">-->
          <!--           <span style="width: 25%;padding-left: 1.5em;">-->
          <!--             <el-checkbox checked disabled>同销方信息、购房信息、发票类型均会合并为一张待开发票。</el-checkbox>-->
          <!--           </span>-->
          <!--          </div>-->
          <!--          <div style="display: flex;padding-top: 15px">-->
          <!--          <span style="padding-left: 1.5em;">-->
          <!--            <el-checkbox v-model="sameDataIdChecked">同业务单号</el-checkbox>-->
          <!--          </span>-->
          <!--            <div style="margin-left: 25px" >-->
          <!--              <i class="el-icon-info"></i>-->
          <!--              <el-input disabled placeholder="按照开票配置>明细合并规则，进行发票/明细合并处理。"-->
          <!--                        style="padding-left:5px;width: 380px"></el-input>-->
          <!--            </div>-->
          <!--          </div>-->
          <div style="padding-bottom: 15px">
            <div style="padding-top: 15px; font-size: 15px; text-align: left">发票明细：</div>
            <div style="display: flex; align-items: center; padding-left: 25px; padding-top: 15px">
              <el-checkbox v-model="itemCheckedRules.code" style="width: 18%">同商品编码</el-checkbox>
              <!--              <el-checkbox v-model="itemCheckedRules.middle" style="width: 18%">同商品中类</el-checkbox>-->
              <el-checkbox v-model="itemCheckedRules.dataId" style="width: 18%">同业务单号</el-checkbox>
              <el-checkbox v-model="itemCheckedRules.name" style="width: 18%">同商品名称</el-checkbox>
              <el-checkbox v-model="itemCheckedRules.unit" style="width: 18%">同商品单位</el-checkbox>
            </div>
            <div style="display: flex; align-items: center; padding-left: 25px; padding-top: 15px">
              <el-checkbox v-model="itemCheckedRules.price" disabled style="width: 18%">同商品单价</el-checkbox>
              <el-checkbox v-model="itemCheckedRules.taxRate" disabled style="width: 18%">同商品税率</el-checkbox>
              <el-checkbox v-model="itemCheckedRules.taxClassCode" style="width: 18%">同税收编码</el-checkbox>
              <el-checkbox v-model="itemCheckedRules.specification" style="width: 18%">同规格型号</el-checkbox>
            </div>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer" style="padding-top: 15px">
        <el-button @click="mergeVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmMerge">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { editItemAmount, getSelectItems, invoiceDetailRedact, selectItems, splitInvoice, sycnMerch2InvoiceItem } from '@/service/sal/invoice';
import { mergeInvoiceItem, splitInvoiceItem } from '@/service/sal/invoice-item';
import { changeMethods, dateOption, tableStyle } from '@/util/mixins';
import { syncByMerchandiseCode } from '@/service/merchandise';
import { getInvoiceMergeConfig, setInvoiceMergeConfig } from '@/service/sal/sal-config';
import InvoiceItemEdit from "@/components/invoice/InvoiceItemEdit";

export default {
  name: 'InvoiceSplit',
  mixins: [tableStyle, dateOption, changeMethods],
  props: {
    invoiceId: {
      type: Number,
      default: 0
    },
    billingFrom: {
      type: String,
      default: ''
    }
  },
  components: {InvoiceItemEdit },
  data() {
    return {
      mergeVisible: false,
      mergeRadio: '1',
      sameDataIdChecked: false,
      itemCheckedRules: {
        code: false,
        middle: false,
        name: true,
        unit: false,
        taxRate: true,
        taxClassCode: false,
        specification: false,
        price: true,
        dataId: false
      },
      customConfig: false,
      formInline: {
        merchandiseCode: '',
        merchandiseName: '',
        taxClassCode: '',
        taxClassName: '',
        invoiceId: 0,
        page: 1,
        size: 15
      },
      pageSizes: [15, 30, 50, 100],
      dataTotal: 0,
      listVisible: false,
      radio: '1',
      dialogVisible: false,
      titleVisible: false,
      titleContent: '',
      tableData: [],
      dataLoading: false,
      selectedData: [],
      invoiceDetails: {},
      invoiceSplitDetails: [],
      tableHeight: 0,
      drawer: false,
      direction: 'rtl',
      dialogFormVisible: false,
      rowData: {},
      taxClassName: '税收分类编码',
      taxClassCode: '',
      merchandiseCode: '',
      taxRate: '',
      isTaxPreferential: '',
      taxPreferentialContent: '',
      taxRateModel: '',
      taxClassVisible: false,
      itemEditVisible: false,
      invoiceItemModel: {
        amount: 0,
        taxAmount: 0,
        sumAmount: 0,
        id: null
      }
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight / 2 - 90;
    });
    this.renderMergeConfig();
  },
  methods: {
    async renderMergeConfig() {
      const { success, data } = await getInvoiceMergeConfig();
      if (success && data && data.customConfig) {
        this.mergeRadio = data.radio;
        this.customConfig = data.customConfig;
        this.itemCheckedRules = data.itemCheckedRules;
        this.sameDataIdChecked = data.sameDataIdChecked;
      }
    },
    async getSelectItems() {
      this.formInline.invoiceId = this.invoiceId;
      let res = await getSelectItems(this.formInline);
      // console.log(res, 'res订单');
      this.tableData = res.data.records;
      this.dataTotal = res.data.total;
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.formInline.size = value;
      this.getSelectItems();
    },
    // 翻页
    handleCurrentChange(value) {
      this.formInline.page = value;
      this.getSelectItems();
    },
    onSubmit() {
      this.getSelectItems();
    },
    onReset() {
      this.formInline = {
        merchandiseCode: '',
        merchandiseName: '',
        taxClassCode: '',
        taxClassName: '',
        invoiceId: 0,
        page: 1,
        size: 15
      };
    },
    handleCloseData() {
      this.onReset();
      this.listVisible = false;
    },
    handleChooseTaxClassCode() {
      this.taxClassVisible = true;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    open() {
      this.listVisible = true;
      if (!!this.invoiceId) {
        this.reqSelectItems(this.invoiceId);
      }
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = '合计';
          return;
        }
        if (['amount', 'taxAmount', 'sumAmount', 'quantity'].indexOf(column.property) == -1) return;
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number(prev) + value;
            } else {
              return Number(prev);
            }
          }, 0);
          if (['amount', 'taxAmount', 'sumAmount'].indexOf(column.property) != -1) sums[index] = '￥' + sums[index].toFixed(2);
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    /* 表格列值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '税率':
          if (['1', 'Y', 1].indexOf(row.isTaxPreferential) != -1) {
            return row.taxPreferentialContent;
          }
          return Number(value * 100) + '%';
        case '税额':
          if (value == 0) return '***';
          return '￥' + value;
        case '金额':
        case '价税合计':
          return '￥' + value;
        case '数量':
          if (value == 0) return '';
          return value;
        case '单价':
          if (value == 0) return '';
          return value;
        case '商品行性质':
          if (value == 0) {
            return '正常';
          } else if (value == 1) {
            return '折扣行';
          } else if (value == 2) {
            return '被折扣行';
          } else {
            return '--';
          }
        default:
          return value;
      }
    },
    /* 是否可选 */
    parseSelectable(row) {
      return row.itemProperty != '1';
    },
    /* 选中明细 */
    handleSelectionChange(row) {
      this.selectedData = row;
    },
    /* 点击明细 */
    handleClickInvoiceItem(row) {
      if (row.itemProperty == '1') return;
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /* 拆分明细 */
    handleSplitInvoiceItem() {
      // 拆分判断
      if (this.selectedData.length > 1 || this.selectedData.length == 0) {
        this.titleContent = '请选择单条数据进行“拆分明细”操作！';
        this.titleVisible = true;
        return;
      }
      let disRows = this.selectedData.filter(item => item.itemProperty == 1);
      if(disRows != null && disRows.length > 0){
        this.titleContent = '不允许选择折扣行数据进行“拆分明细”操作！';
        this.titleVisible = true;
        return;
      }
      this.dialogVisible = true;
      this.invoiceDetails = this.selectedData[0];
      this.invoiceDetails.index = 1;
      let json = JSON.stringify(this.invoiceDetails);
      this.invoiceSplitDetails.push(JSON.parse(json));
    },
    /* 新增明细 */
    handleInvoiceDetailsSplitAdd() {
      // 判断当前输入框是否填写完整
      let quantity = this.invoiceSplitDetails.map((invoice) => invoice.quantity).filter((n) => n);
      let amount = this.invoiceSplitDetails.map((invoice) => invoice.amount);
      let sumAmount = this.invoiceSplitDetails.map((invoice) => invoice.sumAmount);

      if (quantity.includes(0) || quantity.includes('') || quantity.length == 0) {
        if (this.radio == '1' && this.invoiceSplitDetails.length == 1) {
          this.toast('当前数量为空，不可按照数量进行拆分！', 'warning');
          return;
        } else {
          if (amount.includes(0) || amount.includes('') || sumAmount.includes(0) || sumAmount.includes('')) {
            this.toast('请将当前输入框填写完整！', 'warning');
            return;
          }
        }
      }

      // 添加新数据
      let invoice = {
        index:
          Math.max.apply(
            Math,
            this.invoiceSplitDetails.map((item) => item.index)
          ) + 1,
        merchandiseCode: this.invoiceDetails.merchandiseCode,
        merchandiseName: this.invoiceDetails.merchandiseName,
        taxClassCode: this.invoiceDetails.taxClassCode,
        specification: this.invoiceDetails.specification,
        unit: this.invoiceDetails.unit,
        unTaxPrice: this.invoiceDetails.unTaxPrice,
        taxRate: this.invoiceDetails.taxRate,
        quantity: 0,
        amount: 0,
        taxAmount: 0,
        sumAmount: 0
      };
      this.invoiceSplitDetails.push(invoice);
    },
    /* 商品数量拆分 */
    handleCalculateNumber(invoice) {
      // 输入数量判断
      if (Number(invoice.quantity) > Number(this.invoiceSplitDetails[0].quantity)) {
        invoice.quantity = 0;
        this.toast('输入的数量不能大于首行数据！', 'warning');
        return;
      }
      // 金额
      let amount = Number(invoice.quantity).toFixed(8) * Number(invoice.unTaxPrice).toFixed(8);
      invoice.amount = Number(amount).toFixed(2);
      // 税额
      let taxAmount = Number(amount).toFixed(8) * Number(invoice.taxRate).toFixed(8);
      invoice.taxAmount = Number(taxAmount).toFixed(2);
      // 价税合计
      let sumAmount = Number(invoice.amount) + Number(invoice.taxAmount);
      invoice.sumAmount = Number(sumAmount).toFixed(2);
    },
    /* 金额拆分 */
    handleCalculateAmount(invoice) {
      // 输入金额判断
      if (Number(invoice.amount) > Number(this.invoiceSplitDetails[0].amount)) {
        invoice.amount = 0;
        this.toast('输入的金额不能大于首行数据！', 'warning');
        return;
      }
      // 数量
      if (Number(this.invoiceSplitDetails[0].quantity) != 0 && this.invoiceSplitDetails[0].quantity != '') {
        let quantity = Number(Number(invoice.amount).toFixed(8)) / Number(Number(invoice.unTaxPrice).toFixed(8));
        invoice.quantity = this.handleCutZero(Number(quantity).toFixed(8));
      }
      // 税额
      let taxAmount = Number(Number(invoice.amount).toFixed(8)) * Number(Number(invoice.taxRate).toFixed(8));
      invoice.taxAmount = Number(taxAmount).toFixed(2);
      // 价税合计
      let sumAmount = Number(Number(invoice.amount).toFixed(8)) + Number(Number(taxAmount).toFixed(8));
      invoice.sumAmount = Number(sumAmount).toFixed(2);
    },
    /* 价税合计 */
    handleCalculateSumAmount(invoice) {
      // 输入价税合计判断
      if (Number(invoice.sumAmount) > Number(this.invoiceSplitDetails[0].sumAmount)) {
        invoice.sumAmount = 0;
        this.toast('输入的价税合计不能大于首行数据！', 'warning');
        return;
      }

      // 金额
      let amount = Number(Number(invoice.sumAmount).toFixed(8)) / Number(1 + Number(invoice.taxRate)).toFixed(8);
      invoice.amount = Number(amount).toFixed(2);

      // 税额
      let taxAmount = Number(Number(amount).toFixed(8)) * Number(Number(invoice.taxRate).toFixed(8));
      invoice.taxAmount = Number(taxAmount).toFixed(2);

      // 数量
      if (Number(this.invoiceSplitDetails[0].quantity) != 0 && this.invoiceSplitDetails[0].quantity != '') {
        let quantity = Number(Number(amount).toFixed(8)) / Number(Number(invoice.unTaxPrice).toFixed(8));
        invoice.quantity = this.handleCutZero(Number(quantity).toFixed(8));
      }
    },
    /* 首行数据处理 */
    handleFirstRowCalculate() {
      // 原始数据
      let original = this.invoiceDetails;
      // 拆分数据
      let split = this.invoiceSplitDetails.filter((item, index) => {
        if (index != 0) return item;
      });

      // 首行数据
      let firstLineData = this.invoiceSplitDetails[0];
      // 拆分总数量
      let splitQuantity = split.reduce((prev, next) => {
        return Number(prev) + Number(next.quantity);
      }, 0);
      firstLineData.quantity = this.handleCutZero(Number(Number(original.quantity) - Number(splitQuantity)).toFixed(8));
      // 拆分总金额
      let splitAmount = split.reduce((prev, next) => {
        return Number(prev) + Number(next.amount);
      }, 0);
      firstLineData.amount = this.handleCutZero(Number(Number(original.amount) - Number(splitAmount)).toFixed(8));
      // 拆分总税额
      let splitTaxAmount = split.reduce((prev, next) => {
        return Number(prev) + Number(next.taxAmount);
      }, 0);
      firstLineData.taxAmount = this.handleCutZero(Number(Number(original.taxAmount) - Number(splitTaxAmount)).toFixed(8));
      // 拆分总税额
      let splitSumAmount = split.reduce((prev, next) => {
        return Number(prev) + Number(next.sumAmount);
      }, 0);
      firstLineData.sumAmount = this.handleCutZero(Number(Number(original.sumAmount) - Number(splitSumAmount)).toFixed(8));
    },
    /* 拆分明细删除 */
    handleInvoiceSplitDelete(row) {
      let index = this.invoiceSplitDetails.findIndex((item) => item.index == row.index);
      this.invoiceSplitDetails.splice(index, 1);
      this.handleFirstRowCalculate();
    },
    /* 拆分明细确认 */
    handleSpiltDetailsConfirm() {
      // 确认前检查
      let amountIndex = this.invoiceSplitDetails.findIndex((item) => item.amount == 0);
      let sumAmountIndex = this.invoiceSplitDetails.findIndex((item) => item.sumAmount == 0);
      if (sumAmountIndex != -1 || amountIndex != -1) {
        this.toast('价税合计/金额输入有误，无法拆分！', 'warning');
        return;
      }
      // 未拆分点击确定按钮
      if (this.invoiceSplitDetails.length == 1) {
        this.radio = '1';
        this.dialogVisible = false;
        this.selectedData = [];
        this.invoiceSplitDetails = [];
        this.$refs.multipleTable.clearSelection();
        return;
      }

      const amount = this.invoiceDetails.amount;

      if (this.invoiceDetails.itemProperty == '2') {
        const sameCode = this.invoiceDetails.sameCode;
        let discountRow = this.tableData.find((row) => row.sameCode == sameCode && row.itemProperty == '1');
        // console.log('折扣行：', discountRow)
        let discountAmount = discountRow.amount;
        let discountSumAmount = discountRow.sumAmount;
        this.invoiceDetails.discountAmount = discountAmount;
        this.invoiceDetails.discountSumAmount = discountSumAmount;

        let len = this.invoiceSplitDetails.length - 1;
        let ljDiscountAmount = 0,
          ljDiscountSumAmount = 0,
          details = null;
        for (let i = 0; i < len; i++) {
          details = this.invoiceSplitDetails[i];
          // 折扣金额
          const splitDiscountAmount = (Number(details.amount) / Number(amount)) * Number(discountAmount);
          details.discountAmount = splitDiscountAmount.toFixed(2);
          ljDiscountAmount = Number(ljDiscountAmount) + Number(splitDiscountAmount);
          // 折扣税额
          const splitDiscountTaxAmount = Number(splitDiscountAmount) * Number(details.taxRate);
          details.discountTaxAmount = splitDiscountTaxAmount.toFixed(2);
          // 折扣价税合计
          const splitDiscountSumAmount = Number(splitDiscountAmount) + Number(splitDiscountTaxAmount);
          details.discountSumAmount = splitDiscountSumAmount.toFixed(2);
          ljDiscountSumAmount = Number(ljDiscountSumAmount) + Number(splitDiscountSumAmount);
        }

        details = this.invoiceSplitDetails[len];
        details.discountAmount = (Number(discountAmount) - Number(ljDiscountAmount)).toFixed(2);
        details.discountSumAmount = (Number(discountSumAmount) - Number(ljDiscountSumAmount)).toFixed(2);
        details.discountTaxAmount = (Number(details.discountSumAmount) - Number(details.discountAmount)).toFixed(2);
      }

      // 发票拆分
      splitInvoiceItem({
        source: this.invoiceDetails,
        target: this.invoiceSplitDetails,
        billingFrom: this.billingFrom
      }).then((res) => {
        if (res.success) {
          this.toast('明细拆分成功！', 'success');

          const invoiceId = this.invoiceDetails.invoiceId;
          this.reqSelectItems(invoiceId, () => {
            this.radio = '1';
            this.dialogVisible = false;
            this.selectedData = [];
            this.invoiceSplitDetails = [];
          });
        } else {
          this.toast(res.messages, 'error');
        }
      });
    },
    reqSelectItems(invoiceId, callback) {
      this.dataLoading = true;
      selectItems(invoiceId).then((res) => {
        if (res.success) {
          this.tableData = res.data;
          if (callback) callback(res.data);
        }
        this.dataLoading = false;
      });
    },
    /* 拆分明细取消 */
    handleSpiltDetailsCancel() {
      this.radio = '1';
      this.dialogVisible = false;
      this.selectedData = [];
      this.invoiceSplitDetails = [];
      this.$refs.multipleTable.clearSelection();
    },
    confirmMerge() {
      if (this.mergeRadio === '2' && !this.itemCheckedRules.code && !this.itemCheckedRules.name) {
        this.$confirm('商品编码、同商品名称,必须选中一项', '提示', { type: 'warning' });
        return;
      }
      /* itemCheckedRules: {
              code: false,
              middle: false,
              name: true,
              unit: false,
              taxRate: true,
              taxClassCode: false,
              specification: false,
              price: true,
              dataId: false
        }, */
      if (this.mergeRadio === '2' && this.selectedData) {
        if (this.itemCheckedRules.code) {
          let arr = new Set(this.selectedData.map((it) => it.merchandiseCode));
          if (arr.size > 1) {
            this.$confirm('所选明细的#商品编码#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        if (this.itemCheckedRules.name) {
          let arr = new Set(this.selectedData.map((it) => it.merchandiseName));
          if (arr.size > 1) {
            this.$confirm('所选明细的#商品名称#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        if (this.itemCheckedRules.unit) {
          let arr = new Set(this.selectedData.map((it) => it.unit));
          if (arr.size > 1) {
            this.$confirm('所选明细的#商品单位#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        if (this.itemCheckedRules.taxRate) {
          let arr = new Set(this.selectedData.map((it) => it.taxRate));
          if (arr.size > 1) {
            this.$confirm('所选明细的#商品税率#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        if (this.itemCheckedRules.taxClassCode) {
          let arr = new Set(this.selectedData.map((it) => it.taxClassCode));
          if (arr.size > 1) {
            this.$confirm('所选明细的#税收分类编码#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        if (this.itemCheckedRules.specification) {
          let arr = new Set(this.selectedData.map((it) => it.specification));
          if (arr.size > 1) {
            this.$confirm('所选明细的#规格型号#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        if (this.itemCheckedRules.price) {
          let arr = new Set(this.selectedData.map((it) => it.price));
          if (arr.size > 1) {
            this.$confirm('所选明细的#商品单价#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        if (this.itemCheckedRules.dataId) {
          let arr = new Set(this.selectedData.map((it) => it.dataId));
          if (arr.size > 1) {
            this.$confirm('所选明细的#业务单号#不一致，请重新选择！', '提示', { type: 'warning' });
            return;
          }
        }
        const obj = {};
        for (let s of this.selectedData) {
          let rules = '';
          if (this.itemCheckedRules.code) {
            rules += s.merchandiseCode;
          }
          if (this.itemCheckedRules.name) {
            rules += s.merchandiseName;
          }
          if (this.itemCheckedRules.unit) {
            rules += s.unit;
          }
          if (this.itemCheckedRules.taxRate) {
            rules += s.taxRate;
          }
          if (this.itemCheckedRules.taxClassCode) {
            rules += s.taxClassCode;
          }
          if (this.itemCheckedRules.specification) {
            rules += s.specification;
          }
          if (this.itemCheckedRules.price) {
            rules += s.price;
          }
          if (this.itemCheckedRules.dataId) {
            rules += s.dataId;
          }
          obj[rules] = obj[rules] || [];
          obj[rules].push(s);
        }
        if (Object.keys(obj).length > 1) {
          this.$confirm('合并明细不满足合并配置,请仔细检查', '提示', { type: 'warning' });
          return;
        }
      }
      // 合并明细
      let json = {
        items: this.selectedData,
        billingFrom: this.billingFrom
      };
      //自定义配置
      let arr = [];
      if (this.mergeRadio === '2') {
        json.customConfig = true;
        this.customConfig = true;
        if (this.itemCheckedRules.code) {
          arr.push('merchandiseCode');
        }
        if (this.itemCheckedRules.middle) {
          arr.push('middle');
        }
        if (this.itemCheckedRules.taxRate) {
          arr.push('taxRate');
        }
        if (this.itemCheckedRules.name) {
          arr.push('merchandiseName');
        }
        if (this.itemCheckedRules.unit) {
          arr.push('unit');
        }
        if (this.itemCheckedRules.taxClassCode) {
          arr.push('taxClassCode');
        }
        if (this.itemCheckedRules.price) {
          arr.push('price');
        }
        if (this.itemCheckedRules.specification) {
          arr.push('specification');
        }
        json.itemMergeRules = arr;
      }
      mergeInvoiceItem(json).then(async (res) => {
        if (res.success) {
          this.toast('合并明细成功！', 'success');
          this.reqSelectItems(res.data, () => {});
          this.mergeVisible = false;
          //保存配置
          let conf = {
            radio: this.mergeRadio,
            sameDataIdChecked: this.sameDataIdChecked,
            customConfig: this.customConfig,
            itemCheckedRules: this.itemCheckedRules
          };
          await setInvoiceMergeConfig(conf);
        }
      });
    },
    /* 合并明细 */
    handleInvoiceMergeDetails() {
      // 合并明细检查
      let check = this.handleInvoiceMergeDetailsCheck();
      if (!check) return;
      this.mergeVisible = true;
    },
    /* 合并明细检查 */
    handleInvoiceMergeDetailsCheck() {
      // 合并数量检查
      if (this.selectedData.length <= 1) {
        this.titleVisible = true;
        this.titleContent = '请选择至少两条明细数据！';
        return false;
      }
      let disRows = this.selectedData.filter(item => item.itemProperty == 1);
      if(disRows != null && disRows.length > 0){
        this.titleContent = '不允许选择折扣行数据进行“合并明细”操作！';
        this.titleVisible = true;
        return;
      }
      // // 商品名称
      // let merchandiseNames = this.selectedData.map((item) => item.merchandiseName);
      // if ([...new Set(merchandiseNames)].length > 1) {
      //   this.$message.warning('所选明细的商品名称不一致，请重新选择！');
      //   return false;
      // }
      // // 商品单位
      // let units = this.selectedData.map((item) => item.unit);
      // if ([...new Set(units)].length > 1) {
      //   this.$message.warning('所选明细的商品单位不一致，请重新选择！');
      //   return false;
      // }
      // // 商品单价
      // let prices = this.selectedData.map((item) => item.price);
      // if ([...new Set(prices)].length > 1) {
      //   this.$message.warning('所选明细的商品单价不一致，请重新选择！');
      //   return false;
      // }
      // // 规格型号
      // let specifications = this.selectedData.map((item) => item.specification);
      // if ([...new Set(specifications)].length > 1) {
      //   this.$message.warning('所选明细的规格型号不一致，请重新选择！');
      //   return false;
      // }
      // // 商品税率
      // let taxRates = this.selectedData.map((item) => item.taxRate);
      // if ([...new Set(taxRates)].length > 1) {
      //   this.$message.warning('所选明细的商品税率不一致，请重新选择！');
      //   return false;
      // }
      // // 税收编码
      // let taxClassCodes = this.selectedData.map((item) => item.taxClassCode);
      // if ([...new Set(taxClassCodes)].length > 1) {
      //   this.$message.warning('所选明细的税收编码不一致，请重新选择！');
      //   return false;
      // }
      return true;
    },
    /* 拆分发票 */
    handleInvoiceSplitBill() {
      // 检查
      const message = this.validateSplitInvoice();
      if (message != '') {
        this.titleContent = message;
        this.titleVisible = true;
        return;
      }

      // 拆分
      splitInvoice(this.selectedData).then((res) => {
        if (res.success) {
          this.listVisible = false;
          this.$emit('success');
          this.$message.success('拆分发票成功！');
        }
      });
    },
    /* 拆分发票检查 */
    validateSplitInvoice() {
      let disRows = this.selectedData.filter(item => item.itemProperty == 1);
      if(disRows != null && disRows.length > 0){
        return '不允许选择折扣行数据进行“拆分发票”操作！';
      }
      // 非折扣行总数
      const cnt = this.tableData.filter((item) => item.itemProperty != '1').length;

      if (cnt == 1) {
        return '仅一条明细不可拆分发票！';
      }

      if (this.selectedData.length == 0) {
        return '请选择至少一条明细数据！';
      }

      if (this.selectedData.length >= cnt) {
        return '不可将全部明细拆成发票！';
      }

      return '';
    },
    /* 去除末尾多余的0 */
    handleCutZero(num) {
      //拷贝一份 返回去掉零的新串
      let newstr = num;
      //循环变量 小数部分长度
      let leng = num.length - num.indexOf('.') - 1;
      //判断是否有效数
      if (num.indexOf('.') > -1) {
        //循环小数部分
        for (let i = leng; i > 0; i--) {
          //如果newstr末尾有0
          if (newstr.lastIndexOf('0') > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
            let k = newstr.lastIndexOf('0');
            //如果小数点后只有一个0 去掉小数点
            if (newstr.charAt(k - 1) == '.') {
              return newstr.substring(0, k - 1);
            } else {
              //否则 去掉一个0
              newstr = newstr.substring(0, k);
            }
          } else {
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return num;
    },

    handleEdit(row) {
      this.itemEditVisible = true;
      this.rowData = JSON.parse(JSON.stringify(row));
    },
    handleItemEditDialog() {
      this.itemEditVisible = false;
      this.$refs.itemEditForm.resetForm();
      //查询发票明细列表
      this.reqSelectItems(this.invoiceId);
    },
    isNullOrZero(num) {
      return num == null || num == 0;
    },
    async handleSync() {
      if (this.selectedData == null || this.selectedData.length == 0) {
        this.toast('请选择明细行！', 'waring');
        return;
      }
      let ids = this.selectedData.map(item => item.id);
      const { success } = await sycnMerch2InvoiceItem({ids: ids});
      if (success) {
        this.toast('更新完成！', 'success');
        this.onSubmit();
      }
    },

    // dialog 点击确定
    async dialogFormConfirm() {
      // debugger
      this.taxRate = this.taxPreferentialContent == '免税' || this.taxPreferentialContent == '不征税' ? 0.0 : this.taxRateModel;
      const params = {
        id: this.rowData.id,
        taxClassCode: this.taxClassCode,
        taxClassName: this.taxClassName,
        taxRate: this.taxRate,
        isTaxPreferential: this.isTaxPreferential,
        taxPreferentialContent: this.taxPreferentialContent
      };
      try {
        const { success } = await invoiceDetailRedact(params);
        this.dialogDetailVisible = false;
        success &&
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        this.$confirm('是否将此“商品编码”对应的商品信息模块的税分信息及税率同步更新?', '提示').then(() => {
          this.syncMerchandiseInfo();
        });

        this.reqSelectItems(this.invoiceId);
      } catch (error) {
        this.$message({
          message: '修改失败',
          type: 'warning'
        });
      }
    },
    async syncMerchandiseInfo() {
      if (this.merchandiseCode != null && this.merchandiseCode.length > 0) {
        const { success } = await syncByMerchandiseCode({
          taxClassCode: this.taxClassCode,
          taxClassName: this.taxClassName,
          taxRate: this.taxRate,
          merchandiseCode: this.merchandiseCode,
          isTaxPreferential: this.isTaxPreferential,
          taxPreferentialContent: this.taxPreferentialContent
        });
        if (success) {
          this.toast('更新成功！', 'success');
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.el-dialog__body {
  margin-top: 40px;
}
</style>
<style lang="scss" scoped>
.drawer-header {
  margin: 22px 24px 6px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }

  .el-button {
    padding: 0;
  }

  .el-button:hover {
    text-decoration: underline;
  }
}

.drawer-header span:before {
  display: inline-block;
  content: '';
  width: 2px;
  height: 16px;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #333;
}

.content-main {
  margin-top: 0;
  padding: 0px 24px;

  .content-table {
    height: calc(100% - 40px);
  }
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  margin: 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;

  span {
    color: #333333;
  }
}

.dialog-content {
  margin: 24px 24px;
  text-align: left;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-body {
  margin: 0 24px 24px;

  .el-button {
    margin-top: 5px;
    padding: 0;
    width: 20px;
    height: 20px;
    line-height: 0px;
    font-size: 20px;
  }

  .el-input {
    width: auto;
    text-align: center;
  }
}

.dialog-footer {
  text-align: right;
}

::v-deep .el-table .cell {
  height: 34px;
  line-height: 34px;
}

::v-deep .el-input__inner {
  text-align: center;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  text-align: center;
  padding: 0 24px 24px;
}
::v-deep [data-v-4603f0c2] .el-dialog__footer {
  text-align: right;
  padding: 20px 10px 20px 0;
}
::v-deep .el-dialog .el-dialog__body {
  text-align: left;
}

</style>
